import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageHeader 
      title="Page not Found"
      subTitle="This page does not exist!"
    />
  </Layout>
)

export default NotFoundPage
